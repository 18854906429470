<template>
  <el-dialog
  v-dialogDrag
    title="持仓明细"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
 <!-- 持仓明细id、入库单编码、仓库名称、商品名称、商品属性、申请持仓重量、持仓重量、持仓类型（买入持仓、申请持仓）、所属库位、库位区域）（重量带单位） -->
    <div class="viewDetails">
      <el-card class="box-card" v-for="item of holdList" :key="item.id">
        <el-row :gutter="20">
          <el-col :span="7">
            <el-row :gutter="22">
              <el-col :span="12">
                <p class="title">持仓明细ID：</p>
              </el-col>
              <el-col :span="12">
                <p class="text">{{ item.holdDetailId }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row :gutter="22">
              <el-col :span="10">
                <p class="title">入库单编码：</p>
              </el-col>
              <el-col :span="14">
                <p class="text">{{ item.holdCode }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="22">
              <el-col :span="8">
                <p class="title">仓库名称：</p>
              </el-col>
              <el-col :span="16">
                <p class="text">{{ item.storageName }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7">
            <el-row :gutter="22">
              <el-col :span="12">
                <p class="title">商品名称：</p>
              </el-col>
              <el-col :span="12">
                <p class="text">{{ item.className }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row :gutter="22">
              <el-col :span="10">
                <p class="title">申请持仓重量：</p>
              </el-col>
              <el-col :span="14">
                <p class="text">{{ item.applyWeight }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="22">
              <el-col :span="8">
                <p class="title">持仓重量：</p>
              </el-col>
              <el-col :span="16">
                <p class="text">{{ item.holdWeight }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="7">
            <el-row :gutter="22">
              <el-col :span="12">
                <p class="title">持仓类型：</p>
              </el-col>
              <el-col :span="12">
                <p class="text">{{ item.holdTypeName }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="9">
            <el-row :gutter="22">
              <el-col :span="10">
                <p class="title">所属库位：</p>
              </el-col>
              <el-col :span="14">
                <p class="text">{{ item.locationName }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="22">
              <el-col :span="8">
                <p class="title">库位区域：</p>
              </el-col>
              <el-col :span="16">
                <p class="text">{{ item.locationArea }}</p>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="21" v-if="item.specificationsName.length!=0">
            <el-row :gutter="22">
              <el-col :span="4">
                <p class="title">商品属性：</p>
              </el-col>
              <el-col :span="20">
                <span class="text" v-for="(items,index) of item.specificationsName" :key="index">{{ items.label }}</span>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      holdList:[]
    };
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row.holdId);
    },
    getDetails(id) {
      protocolFwd.param_findHoldDetail.param = {
        page: 0,
        size: 1000,
        holdId: id,
        sort: [{
          direction: "DESC",
          property: "holdDetailId"
        }]
      };
      http.postFront(protocolFwd.param_findHoldDetail).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value) {
            for(let i=0;i<value.content.length;i++){
               value.content[i].specificationsName = this.attributeTrans(
              value.content[i].specificationsName
            );
            }
            this.holdList = value.content;
            // this.holdList.push(...this.holdList)
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
.auditCon {
  position: absolute;
  left: 20%;
  top: 20px;
}
.box-card{
  margin-bottom:20px;
}
</style>