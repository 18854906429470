<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>预售持仓申请</p>
      </div>
      <div class="addButton">
        <!-- v-if="right.apply" -->
        <el-button  v-if="right.apply" type="danger" @click="showAddDialog()">预售持仓申请</el-button>
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="7">
          <el-col :span="6"><p class="searchItemTitle">商品分类</p></el-col>
          <el-col :span="18">
            <el-cascader
              class="width-100"
              v-model="selectCategoryOptions"
              :options="categoryOptions"
              :props="categoryProps"
              clearable
            ></el-cascader>
          </el-col>
        </el-col>
        <el-col :span="4">
          <el-col :span="6">
            <p class="searchItemTitle">状态</p>
          </el-col>
          <el-col :span="18">
            <el-select v-model="searchForm.status" placeholder="请选择状态">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-col>
        <!-- <el-col :span="4">
          <el-col :span="6">
            <p class="searchItemTitle">类型</p>
          </el-col>
          <el-col :span="18">
            <el-select v-model="searchForm.type" placeholder="请选择类型">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-col> -->
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="holdId" label="预售持仓ID"></el-table-column>
        <el-table-column prop="sname" label="仓库名称"></el-table-column>
        <el-table-column prop="className" label="商品分类"></el-table-column>
        <el-table-column prop="specificationsName" label="商品属性">
           <template slot-scope="scope">
            <span>{{ scope.row.specificationsName | firstAttributeTransformate}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="quantity" label="数量">
           <template slot-scope="scope">
            <span>{{ scope.row.quantity?scope.row.quantity:'--'}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="putHoldWeight" label="重量">
          <template slot-scope="scope">
            <span>{{ scope.row.putHoldWeight }}{{  scope.row.unit }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="putWarehousTime" label="预计入库时间">
          <template slot-scope="scope">
            <span>{{ scope.row.putWarehousTime | DateFormateDay }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="auditHoldStatus" label="状态">
          <template slot-scope="scope">
            <span>{{ scope.row.auditHoldStatus | cbSupplyNoteStatusFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="showDetails(scope.row)" size="mini">查看</el-button>
            <el-button @click="showSupplementaryDialog(scope.row)" v-if="right.fillPresale&&(scope.row.auditHoldStatus=='T'&&scope.row.holdType=='Y')" type="warning" size="mini">补入库</el-button>
             <!-- v-if="right.cancel && scope.row.status === 0" -->
            <el-button
              v-if="right.cancel &&(scope.row.auditHoldStatus ==='W')"
              @click="cancelApply(scope.row)"
              type="danger"
              size="mini"
            >撤销</el-button>
            <!-- <el-button  v-if="scope.row.holdType!='Y'||(scope.row.holdType=='Y'&&scope.row.holdStatus=='B')" @click="showHoldDetails(scope.row)" size="mini"  type="warning" style="width: 88px!important;margin-top:10px;">持仓明细</el-button> -->
             <el-button
               style="width: 126px!important;margin-top:10px;"
                v-if="right.sureFillHold&&scope.row.auditHoldStatus=='T'&&scope.row.holdType=='Y'&&scope.row.holdStatus=='B'"
                @click="sureFillHold(scope.row.holdId)"
                type="warning"
                size="mini"
              >确认完成补入库</el-button>
            <!-- <span style="display:none" v-dialogDrag></span> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
    <!-- 新入库仓单 -->
    <newDialog ref="newDialog" @reLoad="getDatas()" />
    <!-- 补入库仓单 -->
    <supplementaryDialog ref="supplementaryDialog" @reLoad="getDatas()" />
    <!-- 持仓明细 -->
    <holdDetailDialog ref="viewHoldDialog" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import viewDialog from "./viewDialog";
import newDialog from "./newDialog";
import holdDetailDialog from "../../salesMgt/myPosition/holdDetail";
import supplementaryDialog from "./supplementaryDialog";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      selectCategoryOptions:[],
      categoryProps: {
        label: "className",
        value: "classId",
        checkStrictly: false
      },
      categoryOptions:[],
      searchForm: {
        commodityName: null,
        status: null,
        type: null
      },
      statusOptions: [
        { value: 'W', label: "待审核" },
        { value: 'T', label: "审核通过" },
        { value: 'F', label: "审核不通过" },
        { value: 'U', label: "已撤销" },
      ],
      typeOptions: [
        { value: 0, label: "申请入库" },
        { value: 1, label: "补入库" },
      ],
      right: {
        apply: false,
        cancel: false,
        fillPresale:false,
        sureFillHold: false
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    viewDialog,
    newDialog,
    supplementaryDialog,
    holdDetailDialog
  },
  mounted() {
    this.getDatas();
    this.getRights();
    this.getCommodityCategory();
  },
  methods: {
    getRights() {
      this.loadRight().then((loadResult) => {
        if (loadResult == 'loadRightSuccess') {
          this.right.apply = this.isHasRight('tc-trade-generatePresaleHold');
          this.right.cancel = this.isHasRight('tc-trade-auditHoldForWeb');
          this.right.fillPresale = this.isHasRight('tc-trade-fillApplyWarehous');
          this.right.sureFillHold = this.isHasRight(
            "tc-trade-fillHold"
          );
        }
      })
    },
    getzh(){
      let myBox = document.getElementsByClassName('el-message-box')
      myBox.bind('dialogDrag')
      // console.log(myBox)
      // console.log('zhiding')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.searchForm = {
        commodityName: null,
        holdStatus: null,
        type: null
      };
      this.selectCategoryOptions = []
      this.getDatas();
    },
     showHoldDetails(row) {
      this.$refs.viewHoldDialog.showDialog(row);
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = []
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if(element&&element.children){
          if(element.children.length==0){
            delete element['children']
          }else{
            this.clearChildren(element.children)
          }
        }
      });
      return Arr
    },
    sureFillHold(id){
      this.$confirm("温馨提示：确认完成补入库后将不能再补入库，如补入库重量与订单重量比例不达标，将面临违约，会产生违约金，请仔细确认！", "您确认补入库完成吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass:'ruku',
        type: "warning",
      })
        .then(() => {
          protocolFwd.param_fillHold.param.holdId = id;
          http.postFront(protocolFwd.param_fillHold).then((response) => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    getDatas() {
      protocolFwd.param_queryHold.param.page = this.current - 1;
      protocolFwd.param_queryHold.param.size = this.pageSize
      protocolFwd.param_queryHold.param.commodityId = this.selectCategoryOptions.length>0?this.selectCategoryOptions[this.selectCategoryOptions.length-1]:null
      protocolFwd.param_queryHold.param.auditHoldStatus = null
      protocolFwd.param_queryHold.param.sort = [{property: "createTime", direction: "DESC"}];
      protocolFwd.param_queryHold.param.holdType = ['Y','B']
      protocolFwd.param_queryHold.param.holdId = null
      protocolFwd.param_queryHold.param.specificationsId = null;
      protocolFwd.param_queryHold.param.holdStatus =  this.searchForm.status;
      protocolFwd.param_queryHold.param.specificationsName = null;
      protocolFwd.param_queryHold.param.sellStatus = null;

      http.postFront(protocolFwd.param_queryHold).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableDatas = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    showAddDialog() {
      this.$refs.newDialog.showDialog();
    },
    showSupplementaryDialog(row) {
       const tempData = JSON.parse(JSON.stringify(row));
      this.$refs.supplementaryDialog.showDialog(tempData);
    },
    showDetails(row) {
      this.$refs.viewDialog.showDialog(row);
    },
    // 撤销
    cancelApply(row) {
    // v-dialogDrag
    console.log(row)
      // this.getzh()
      this.$confirm("请确认是否撤销该持仓申请吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_auditHold.param.auditHoldStatus =
            'U';
          protocolFwd.param_auditHold.param.holdId =
            row.holdId;
          http.postFront(protocolFwd.param_auditHold).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.searchList {
  margin: 10px 0;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th{
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox{
    line-height: 30px;
    margin-left: 20px;
  }
}
.subPage .pagination {
  margin-top: 15px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>
