import { render, staticRenderFns } from "./holdDetail.vue?vue&type=template&id=5e10198e&scoped=true"
import script from "./holdDetail.vue?vue&type=script&lang=js"
export * from "./holdDetail.vue?vue&type=script&lang=js"
import style0 from "./holdDetail.vue?vue&type=style&index=0&id=5e10198e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e10198e",
  null
  
)

export default component.exports