<template>
  <el-dialog
  v-dialogDrag
    title="预售持仓申请单"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div>
      <el-form ref="form" :rules="rules" :model="form" label-position="left">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="商品分类" prop="commodityId" label-width="100px">
              <el-cascader
                class="width-100"
                v-model="form.commodityId"
                :options="categoryOptions"
                :props="categoryProps"
                :show-all-levels="false"
                ref="cascader"
              ></el-cascader>
            </el-form-item> 
          </el-col>
          <el-col :span="12" v-for="(item,index) of gradeIdOptions" :key="index">
             <el-form-item :label="item.name" prop="specificationsName" label-width="100px">
                <el-select class="width-100" v-model="form.specificationsName[index]">
                  <el-option
                    v-for="items in item.children"
                    :key="items.id"
                    :label="items.value"
                    :value="item.value+','+items.value"
                  ></el-option>
                </el-select>
              </el-form-item>
          </el-col>
           <el-col :span="12">
            <el-form-item label="生产厂家" prop="manufacturer" label-width="100px">
              <el-select class="width-100" v-model="form.manufacturer" clearable>
                <el-option
                  v-for="(item,index) in manufacturerList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="12">
            <el-form-item label="产地" prop="placeOrigin" label-width="100px">
              <el-select class="width-100" v-model="form.placeOrigin" clearable>
                <el-option
                  v-for="(item,index) in placeOriginList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="12">
            <el-form-item label="品牌" prop="brand" label-width="100px">
              <el-select class="width-100" v-model="form.brand" clearable>
                <el-option
                  v-for="(item,index) in brandList"
                  :key="index"
                  :label="item.name"
                  :value="item.name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            
           <el-col :span="12">
            <el-form-item label="仓库" prop="storageId" label-width="100px">
              <el-select class="width-100" v-model="form.storageId" @change="unitChange">
                <el-option
                  v-for="item in storageIdOptions"
                  :key="item.storageId"
                  :label="item.name"
                  :value="item.storageId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="数量" prop="quantity" label-width="100px">
              <el-input v-model.number="form.quantity"></el-input>
            </el-form-item>
            <!-- -->
          </el-col>
          <el-col :span="12">
            <el-form-item label="重量" prop="putHoldWeight" label-width="100px">
              <el-input v-model="form.putHoldWeight">
              </el-input>
            </el-form-item>
          </el-col>
        
           <el-col :span="12">
            <el-form-item label="重量单位" prop="weightUnitId" label-width="100px">
               <el-select class="width-100" v-model="form.weightUnitId" placeholder="请先选择仓库，再选择重量单位
">
                <el-option
                  v-for="items in weightUnitOptions"
                  :key="items.weightUnitId"
                  :label="items.name"
                  :value="items.weightUnitId"
                ></el-option>
              </el-select>
              <!-- <el-input v-model="form.unit"></el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="磅差" prop="poundsPoor" label-width="100px">
              <el-input v-model="form.poundsPoor">
                <template slot="prepend">±</template>
                <template slot="append">{{selectWeightUnit.name}}</template>
              </el-input>
            </el-form-item>
          </el-col>
           
            <el-col :span="12">
            <el-form-item label="商品图片" prop="img" label-width="100px">
              <el-upload
                class="avatar-uploader"
                :action="actionUrl"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :data="postData"
              >
                <img v-if="form.img" :src="form.img" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
         <!-- <el-row :gutter="20">
        
        </el-row> -->
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="confirmAddProduct()" :loading="isAddLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      //  if(value==""){
      //     callback(new Error('请输入数量'));
      //  }
      if(value){
           if (!reg.test(value)) { 
        return callback(new Error('请输入正整数'));
      }else{
        callback()
      }
      }else{
          callback()
      }
    
    };
    var checkSpecName = (rule, value, callback) => {
      // console.log(this.gradeIdOptions.length!=0&&value.length!=this.gradeIdOptions.length)
      if(this.gradeIdOptions.length!=0&&value.length!=this.gradeIdOptions.length){
         return callback(new Error("请选择商品属性"));
      } else{
         callback();
      }
    }
    var checkPoundsPoor = (rule,value,callback) => {
      let reg=/^\d+(\.\d{0,2})?$/;
      if (value=='') {
        return callback(new Error("请输入磅差"));
      }
      else if (!reg.test(value)) {
        return callback(new Error("磅差必须是数字且不能超过两位小数"));
      } else {
        callback()
      }
    }
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg=/^\d+(\.\d{0,2})?$/;
      if (value=='') {
        return callback(new Error("请输入重量"));
      }
      else if (!reg.test(value)||Number(value)<=0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else {
        callback()
      }
    };
    return {
      dialogVisible: false,
      form: {
        commodityId: null,// 商品id
        img: null,// 商品图片
        poundsPoor: null,// 磅差
        putHoldWeight: null, //重量
        quantity: null,//数量
        specificationsId: [],//规格id 1-1
        specificationsName: [],//规格名称{颜色：红色}json形式
        weightUnitId: "",// 重量单位
        storageId: null,// 仓库
        brand:null,// 品牌
        placeOrigin:null,// 产地
        manufacturer:null// 厂家
      },
      brandList:[],
      manufacturerList:[],
      placeOriginList:[],
      rules: {
        commodityId: [
          { required: true, message: "请选择商品品种", trigger: "blur" }
        ],
          specificationsName: [{ required: false, message: "请选择商品属性", trigger: "change" },
          { validator: checkSpecName, trigger: "blur" }
        ],
        quantity: [
          { required: false, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: 'blur' }
        ],
        specificationsName: [
          { required: false, message: "请输入规格名称", trigger: "change" }
        ],
        specificationsId: [
          { required: false, message: "请选择规格", trigger: "change" }
        ],
        putHoldWeight: [{ required: true, message: "请输入重量", trigger: "change" },
          { validator: checkPutHoldWeight, trigger: 'change' }
        ],
        poundsPoor: [{ required: true, message: "请输入磅差", trigger: "change" },{
          validator: checkPoundsPoor,trigger: 'change'
        }],
        placeOrigin: [{ required: true, message: "请选择产地", trigger: "change" }],
        weightUnitId: [{ required: true, message: "请选择重量单位", trigger: "change" }],
        storageId: [{ required: true, message: "请选择仓库", trigger: "change" }],

        img: [{ required: true, message: "请输入图片", trigger: "change" }]
      },
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      categoryProps: {
        label: "className",
        value: "classId"
      },
      gradeIdProps:{
        label: "value",
        value: "value"
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      storageIdOptions: [],
      isAddLoading: false,
      newCommodityId:null,
      weightUnitOptions:[],
      selectWeightUnit:{}
    };
  },
  watch:{
    'form.commodityId': {
      handler(val, oldVal) {
         this.gradeIdOptions = [];
        this.form.specificationsName = [];
        this.getGradeIdOptions();
      }
    },
    'form.weightUnitId':{
      handler(val, oldVal) {
        let that = this
        for(let i=0;i<this.weightUnitOptions.length;i++){
          if(val==that.weightUnitOptions[i].weightUnitId){
            that.selectWeightUnit = that.weightUnitOptions[i]
          }
        }
      }
    }
   
  },
  methods: {
     // 当前选中仓库
    unitChange (e) {
      this.weightUnitOptions = []
      this.form.weightUnitId = ''
      this.selectWeightUnit.name = ''
      protocolFwd.param_unitOrWarehouse.param.storageId = e
      http.postFront(protocolFwd.param_unitOrWarehouse).then(response => {
        console.log(response)
        let {value, code, message} = response.data
        if(code == 0) {
          this.weightUnitOptions = value.content
        }
      })
    },
    showDialog() {
      const _cascader = this.$refs.cascader
      if (_cascader) {
          _cascader.$refs.panel.checkedValue = []
          _cascader.$refs.panel.activePath = [] 
          _cascader.$refs.panel.syncActivePath()
      }
      this.dialogVisible = true;
      this.getCommodityCategory();
      this.getfindWeightUnit();
      this.getStorageIdOptions();
      this.getAreaMBrand()
    },
     queryMe(companyName,list){
      if(companyName&&companyName.indexOf(',')!=-1){
         let newCompany = companyName.split(',')
          for(let i=0;i<newCompany.length;i++){
            let obj = {'name':newCompany[i]}
            list.push(obj)
          }
      }else if(companyName&&companyName.indexOf(',')==-1){
        let obj = {'name':companyName}
        list.push(obj)
      }else{
        list = []
      }
    },
    getAreaMBrand(){
      protocolFwd.param_getInformation.param.firmId = sessionStorage.getItem('userFirmId')
      http.postFront(protocolFwd.param_getInformation).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if(value){
             this.queryMe( value.manufacturer,this.manufacturerList)
            this.queryMe( value.brand,this.brandList)
            this.queryMe( value.placeOrigin,this.placeOriginList)
          }
         if(value==null){
           this.brandList = []
           this.manufacturerList = []
           this.placeOriginList = []
         }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },   
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.deleteCategoryOptionsLast(value);
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getfindWeightUnit() {
        http.postFront(protocolFwd.param_findWeightUnit).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          // this.weightUnitOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.form.img = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children)
        } else {
          delete item.children
        }
      }
    },
    getGradeIdOptions() {
       if (Array.isArray(this.form.commodityId)) {
            this.newCommodityId = this.form.commodityId[
              this.form.commodityId.length - 1
            ];
          } else {
           this.newCommodityId = this.form.commodityId;
          }
       protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = this.newCommodityId
      //  protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = 65850
       http.postFront(protocolFwd.param_queryTemplateGroup).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
           if(value.length!=0){
              let goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate)
              console.log(111,value[0].paramTemplate)
              for(let i=0;i<goodsGroupCommonSpec.length;i++){
                goodsGroupCommonSpec[i].id = value[0].id;
                goodsGroupCommonSpec[i].value = goodsGroupCommonSpec[i].name;
                goodsGroupCommonSpec[i].children = goodsGroupCommonSpec[i].content;
              }
              this.gradeIdOptions = goodsGroupCommonSpec
              console.log(this.gradeIdOptions)
           }else{
              this.gradeIdOptions = []
           }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    confirmAddProduct() {
      this.$refs["form"].validate(valid => {
        if (valid) {
         let specificationsNames = {} // 对象
          if(this.form.specificationsName.length!=0){
            for(let i=0;i<this.form.specificationsName.length; i++){
             let currentObj ={}
              let str = this.form.specificationsName[i];
              let str1 = str.split(',')
              currentObj[str1[0]] = str1[1]
              specificationsNames = Object.assign(specificationsNames,currentObj)
              // specificationsNames =specificationsNames.push(this.form.specificationsName[0])
            } 
          }
          if(JSON.stringify(specificationsNames) == "{}"){
            specificationsNames = null
          }
          protocolFwd.param_generatePresaleHold.param.commodityId = this.newCommodityId;
          protocolFwd.param_generatePresaleHold.param.img = this.form.img;
          protocolFwd.param_generatePresaleHold.param.poundsPoor = this.form.poundsPoor;
          protocolFwd.param_generatePresaleHold.param.putHoldWeight = this.form.putHoldWeight;
          protocolFwd.param_generatePresaleHold.param.quantity = this.form.quantity;
          protocolFwd.param_generatePresaleHold.param.storageId = this.form.storageId;
          protocolFwd.param_generatePresaleHold.param.brand = this.form.brand;
          protocolFwd.param_generatePresaleHold.param.placeOrigin = this.form.placeOrigin;
          protocolFwd.param_generatePresaleHold.param.manufacturer = this.form.manufacturer;
          // protocolFwd.param_generatePresaleHold.param.specificationsId = this.form.specificationsId;
          protocolFwd.param_generatePresaleHold.param.specificationsName = specificationsNames;
          protocolFwd.param_generatePresaleHold.param.weightUnitId = this.form.weightUnitId;
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_generatePresaleHold)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.$EL_MESSAGE("提交成功！待后台审核通过后方可生成预售持仓");
                this.handleClose()
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        commodityId: null,// 商品id
        img: null,// 商品图片
        poundsPoor: null,// 磅差
        putHoldWeight: null, //重量
        quantity: null,//数量
        specificationsId: [],//规格id 1-1
        specificationsName: [],//规格名称{颜色：红色}json形式
        weightUnitId: "",// 重量单位
        storageId: null,// 仓库
        brand:null,// 品牌
        placeOrigin:null,// 产地
        manufacturer:null// 厂家
      };
       this.brandList=[]
      this.manufacturerList=[]
      this.placeOriginList=[]
      this.selectWeightUnit = {}
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
      this.weightUnitOptions = []
      this.dialogVisible = false;
    }
  }
};
</script>